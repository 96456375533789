import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db, auth } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../styles/MenuBar.css';

const MenuBar = () => {
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [hasUncheckedLikes, setHasUncheckedLikes] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false); // 追加
  const [user] = useAuthState(auth);

  useEffect(() => {
    const fetchUnreadMessages = async () => {
      if (user) {
        try {
          const chatQuery = query(collection(db, 'chats'), where('participants', 'array-contains', user.uid));
          const chatSnapshot = await getDocs(chatQuery);

          let unread = false;
          await Promise.all(chatSnapshot.docs.map(async (chat) => {
            // 非表示チャットを除外
            if (chat.data().notdisplay && chat.data().notdisplay.includes(user.uid)) {
              return;
            }

            const messageQuery = query(collection(db, 'chats', chat.id, 'messages'), where('read', '==', false), where('sender', '!=', user.uid));
            const messageSnapshot = await getDocs(messageQuery);
            if (!messageSnapshot.empty) {
              unread = true;
            }
          }));

          setHasUnreadMessages(unread);
        } catch (error) {
          console.error('Error fetching unread messages:', error);
        }
      }
    };

    const fetchUncheckedLikes = async () => {
      if (user) {
        try {
          const likeQuery = query(collection(db, 'likes'), where('to', '==', user.uid), where('check', '==', false));
          const likeSnapshot = await getDocs(likeQuery);

          if (!likeSnapshot.empty) {
            setHasUncheckedLikes(true);
          }
        } catch (error) {
          console.error('Error fetching unchecked likes:', error);
        }
      }
    };

    const fetchUnreadNotifications = async () => {
      if (user) {
        try {
          const notificationsQuery = query(
            collection(db, 'members', user.uid, 'notifications'),
            where('read', '==', false)
          );
          const notificationsSnapshot = await getDocs(notificationsQuery);

          if (!notificationsSnapshot.empty) {
            setHasUnreadNotifications(true);
          }
        } catch (error) {
          console.error('Error fetching unread notifications:', error);
        }
      }
    };

    fetchUnreadMessages();
    fetchUncheckedLikes();
    fetchUnreadNotifications(); // 追加
  }, [user]);

  return (
    <div className="menu-bar">
      <Link to="/member_search">
        <div className="menu-item">
          <img src="/images/search-icon.png" alt="Search" />
          <span>探す</span>
        </div>
      </Link>
      <Link to="/timeline">
        <div className="menu-item">
          <img src="/images/timeline.png" alt="Good" />
          <span>タイムライン
          </span>
        </div>
      </Link>
      <Link to="/iine">
        <div className="menu-item">
          <img src="/images/iine-icon.png" alt="Good" />
          {hasUncheckedLikes && <span className="unread-badge-iine"></span>}
          <span>いいね</span>
        </div>
      </Link>
      <Link to="/inbox">
        <div className="menu-item">
          <img src="/images/inbox-icon.png" alt="Inbox" />
          {hasUnreadMessages && <span className="unread-badge"></span>}
          <span>メッセージ</span>
        </div>
      </Link>
      <Link to="/settings">
        <div className="menu-item">
          <img src="/images/profile-icon.png" alt="Profile" />
          {hasUnreadNotifications && <span className="unread-badge"></span>} {/* 追加 */}
          <span>メニュー</span>
        </div>
      </Link>
    </div>
  );
};

export default MenuBar;
